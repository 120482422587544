import './App.css'
import '@aws-amplify/ui-react/styles.css'
import type { ReactElement } from 'react'
import { AlignmentTable } from './components/AlignmentTable'
import { Amplify, Auth, Hub, Logger } from 'aws-amplify'
import { AppBar } from './components/AppBar'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { MessageComponent } from '@syncfusion/ej2-react-notifications'
import { useEffect, useState, useCallback } from 'react'

/**
 * ----------------------------------------------------------------------------
 * Logging
 * ----------------------------------------------------------------------------
 */
const isProduction = process.env.NODE_ENV === 'production'

const logger = new Logger('App', isProduction ? 'ERROR' : 'INFO')

/**
 * ----------------------------------------------------------------------------
 * Authentication
 * ----------------------------------------------------------------------------
 */
const isLocalhost = Boolean(window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/))

// https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/#set-up-backend-resources
Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'us-east-1:ef7cb03e-586a-4a4e-a55e-0643fb93bd90', // integration-hub-dev

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION, // 'us-east-1',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: process.env.REACT_APP_AWS_REGION, // 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID, // 'us-east-1_xdqf1Wxuv',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID, // '5e6lgkcs6s14n2lq0pi3i8btkq',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,

    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    // signUpVerificationMethod: 'code', // 'code' | 'link'

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //domain: isLocalhost ? 'localhost' : 'site.alignment-table.eta.its.utexas.edu',
      domain: isLocalhost ? 'localhost' : process.env.REACT_APP_COOKIE_DOMAIN,
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 30,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: 'strict', // 'strict' | 'lax',
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: isLocalhost ? false : true,
    },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN, // 'adfs-poc.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: isLocalhost ? 'http://localhost:3000' : process.env.REACT_APP_SITE_URL,
      redirectSignOut: isLocalhost ? 'http://localhost:3000' : process.env.REACT_APP_SITE_URL,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
})

/**
 * ----------------------------------------------------------------------------
 * Application
 * ----------------------------------------------------------------------------
 */
const App = (): ReactElement => {
  const [user, setUser] = useState<{ username?: string; idToken: string; csuNames: string[] }>({ idToken: '', csuNames: [] })
  const [error, setError] = useState<string | null>(null)
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false)

  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()

  let currentYear = currentDate.getFullYear()

  if (currentMonth >= 0 && currentMonth <= 7) {
    currentYear -= 1
  }

  const nextYear = currentYear + 1

  let lastYear = null

  if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
    lastYear = currentYear - 1
  }

  useEffect(() => {
    let isSubscribed = true

    async function checkUser() {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        const userSession = cognitoUser.getSignInUserSession()

        // logger.info('cognitoUser', cognitoUser)
        // logger.info('idToken', userSession?.getIdToken()?.getJwtToken())

        const user = {
          username: cognitoUser.getUsername(),
          idToken: userSession?.getIdToken()?.getJwtToken(),
          csuNames: JSON.parse(userSession?.getIdToken()?.decodePayload().csuNames),
        }

        // logger.info('user', user)

        if (isSubscribed) {
          setUser(user)
        }
      } catch (error) {
        logger.debug('Failed to get user', error)
      }
    }

    checkUser()

    // Subscribe to 'auth' events from AWS Amplify Hub and log events
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      logger.info('event', event)
      switch (event) {
        case 'signIn':
          break
        case 'cognitoHostedUI':
          checkUser()
          logger.info('user signed in')
          break
        case 'signOut':
          break
        case 'oAuthSignOut':
          if (isSubscribed) {
            setUser({ idToken: '', csuNames: [] })
          }
          logger.info('user signed out')
          break
        default:
          logger.warn(`unhandled event: ${event}`, data)
          break
      }
    })

    // Unsubscribe from 'auth' events when component is unmounted
    return () => {
      isSubscribed = false
      unsubscribe()
    }
  }, [])

  /**
   * Sign in to the application using the ADFS identity provider
   *
   * @returns {Promise<void>}
   */
  const handleLogin = useCallback(async (): Promise<void> => {
    setIsLoggingIn(true) // Start the login process

    try {
      await Auth.federatedSignIn({
        customProvider: process.env.REACT_APP_CUSTOM_PROVIDER_NAME || '',
      })
    } catch (error: unknown) {
      const message = 'An error occurred during login: ' + (error as Error).message
      setError(message)
      logger.error(message)
    } finally {
      setIsLoggingIn(false) // End the login process
    }
  }, [])

  /**
   * Sign out of the application and redirect to the ADFS logout page
   *
   * The ADFS logout page will redirect back to the application after
   * the user has been logged out of ADFS.
   *
   * @returns {Promise<void>}
   *
   * @todo The logout url does not work when running the application locally
   *
   * @see https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html
   */
  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      await Auth.signOut()
    } catch (error: unknown) {
      const message = 'An error occurred during logout: ' + (error as Error).message
      setError(message)
      logger.error(message)
    }
  }, [])

  const resetPersistedState = (): void => {
    try {
      // grid.enablePersistence = false
      window.localStorage.setItem('gridGrid', '')
      // grid.destroy()
      location.reload()
    } catch (error: unknown) {
      const message = 'An error occurred during reset: ' + (error as Error).message
      logger.error(message)
    }
  }

  return (
    <div className="app">
      <div className="app-header">
        <Header />
        <AppBar
          isLoggedIn={!!user.username}
          username={user.username || ''}
          onLogin={handleLogin}
          onLogout={handleLogout}
          resetPersistedState={resetPersistedState}
          setError={setError}
          isLoggingIn={isLoggingIn}
        />
      </div>
      <div className="app-message">
        {error && (
          <MessageComponent
            id="msg_default"
            content="Editing is restricted"
            showCloseIcon={true}
          />
        )}
      </div>
      <div className="app-body">
        <AlignmentTable
          isLoggedIn={!!user.username}
          idToken={user.idToken}
          csuNames={user.csuNames}
          fiscalYears={lastYear ? [lastYear.toString(), currentYear.toString(), nextYear.toString()] : [currentYear.toString(), nextYear.toString()]}
        />
      </div>
      <div className="app-footer">
        <Footer />
      </div>
    </div>
  )
}

export default App
