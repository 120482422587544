import React from 'react'
import { ActionEventArgs } from '@syncfusion/ej2-react-grids'
import { BeforeBatchSaveArgs } from '@syncfusion/ej2-react-grids'
import { BeforeDataBoundArgs } from '@syncfusion/ej2-react-grids'
import { CellSelectEventArgs } from '@syncfusion/ej2-react-grids'
import { ChangeEventArgs } from '@syncfusion/ej2-react-dropdowns'
import { Column } from '@syncfusion/ej2-react-grids'
import { ColumnChooser } from '@syncfusion/ej2-react-grids'
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-grids'
import { CsuNamePicker } from './CsuNamePicker'
import { DataManager } from '@syncfusion/ej2-data'
import { DialogUtility } from '@syncfusion/ej2-react-popups'
import { DropDownList } from '@syncfusion/ej2-react-dropdowns'
import { DropDownTree } from '@syncfusion/ej2-react-dropdowns'
import { Edit } from '@syncfusion/ej2-react-grids'
import { FailureEventArgs } from '@syncfusion/ej2-react-grids'
import { Filter } from '@syncfusion/ej2-react-grids'
// import { FilteringEventArgs } from '@syncfusion/ej2-react-dropdowns'
import { FiscalYearPicker } from './FiscalYearPicker'
import { Freeze } from '@syncfusion/ej2-react-grids'
import { GridComponent } from '@syncfusion/ej2-react-grids'
import { Group } from '@syncfusion/ej2-react-grids'
import { IEditCell } from '@syncfusion/ej2-react-grids'
import { Inject } from '@syncfusion/ej2-react-grids'
import { LoadEventArgs } from '@syncfusion/ej2-react-grids'
import { Logger } from 'aws-amplify'
import { Page } from '@syncfusion/ej2-react-grids'
import { Predicate } from '@syncfusion/ej2-data'
import { Query } from '@syncfusion/ej2-data'
// import { QueryCellInfoEventArgs } from '@syncfusion/ej2-react-grids'
import { RecordDoubleClickEventArgs } from '@syncfusion/ej2-react-grids'
import { Resize } from '@syncfusion/ej2-react-grids'
import { Selection } from '@syncfusion/ej2-react-grids'
import { Sort } from '@syncfusion/ej2-react-grids'
import { Toolbar } from '@syncfusion/ej2-react-grids'
import { UrlAdaptor } from '@syncfusion/ej2-data'
import { getValue } from '@syncfusion/ej2-base'
import { Grid } from '@syncfusion/ej2-grids'
// import { FormValidator } from '@syncfusion/ej2-inputs'

/**
 * ----------------------------------------------------------------------------
 * Logging
 * ----------------------------------------------------------------------------
 */
const isProduction = process.env.NODE_ENV === 'production'

const logger = new Logger('AlignmentTable', isProduction ? 'ERROR' : 'INFO')

/**
 * ----------------------------------------------------------------------------
 * Types and Interfaces
 * ----------------------------------------------------------------------------
 */
interface AlignmentTableProps {
  isLoggedIn?: boolean
  idToken: string
  csuNames: string[]
  fiscalYears: string[]
}

type Record = {
  [key: string]: string | number | null | undefined
}

export const AlignmentTable: React.FC<AlignmentTableProps> = ({ isLoggedIn = false, idToken, csuNames, fiscalYears }) => {
  // let grid: GridComponent | null
  let grid: Grid | null

  const allColumns = [
    'aq_137_account_aq_comp',
    'aq_138_budget_group_aq_comp',
    'aq_account_nbr',
    'aq_account_status',
    'aq_comp',
    'aq_dept_short',
    'aq_dept_unit',
    'aq_fy',
    'aq_short',
    'aq_short_title',
    'aq_unit',
    'aq_unit_head_eid',
    'aq_unit_head_name',
    'aq_unit_subcode',
    'aq_vp_dean_short',
    'aq_vp_dean_unit',
    'bg_account_nbr',
    'bg_group_status',
    'bg_short_title',
    'csu_alignment_md5_key', // New as of 2021-08-30, primary key
    'csu_program',
    // 'csu_program_group',
    'csu_project',
    'csu_project_exp_date',
    // 'csu_project_group',
    'csu_report_unit',
    'csu_report_unit_title', // New as of 2023-08-26
    'csu_updated_by',
    'csu_updated_ts',
    // 'etl_batch_id',
    // 'etl_updated_ts',
    // 'etl_user_id',
    // 'count',
  ]

  /*
   * Data sources
   */
  const data = new DataManager({
    adaptor: new UrlAdaptor(),
    url: `${process.env.REACT_APP_API_URL}/records`,
    batchUrl: `${process.env.REACT_APP_API_URL}/records/batch`,
    headers: [{ Authorization: `Bearer ${idToken}` }],
  })

  /*
   * Queries
   */
  let query = new Query().select(allColumns)

  if (fiscalYears?.[0] && csuNames?.[0]) {
    query = new Query().select(allColumns).where(new Predicate('aq_fy', 'equal', fiscalYears[0]).and('aq_vp_dean_short', 'equal', csuNames[0]))
  }

  /*
   * Alert the user if they try to edit a read only column
   */
  const handleDoubleClick = (args: RecordDoubleClickEventArgs) => {
    const columnName = args.column?.headerText
    const isEditable = args.column?.allowEditing

    if (!isEditable) {
      DialogUtility.alert({
        title: 'Read Only',
        width: '250px',
        content: columnName ? `${columnName} is read only` : 'This field is read only',
        position: { X: 'center', Y: 'center' },
        closeOnEscape: true,
      })
    }
  }

  /*
   * Autofit columns after column chooser changes
   */
  const handleActionComplete = (args: ActionEventArgs) => {
    if (args.requestType === 'columnstate') {
      grid?.refreshColumns()
      // grid?.refresh()
    }
  }

  /*
   * API Gateway doesn't handle input that is either a string or null. In order to validate the input, we need to convert
   * null to an empty string. This is a workaround until we can get API Gateway to handle null.  This function only checks
   * fields of columns that change during a batch update or might be null (csu_updated_by and csu_updated_ts).
   */
  const handleBeforeBatchSave = (args: BeforeBatchSaveArgs) => {
    const columns = new Set(['csu_report_unit', 'csu_program', 'csu_project', 'csu_project_exp_date', 'csu_report_unit_title', 'csu_updated_by', 'csu_updated_ts'])

    if (!args.batchChanges || !('changedRecords' in args.batchChanges)) {
      return
    }

    const records = args.batchChanges.changedRecords as Record[]

    for (const record of records) {
      for (const key in record) {
        if (columns.has(key) && record[key] === null) {
          record[key] = ''
        }
      }
    }
  }

  /*
   * Alert the user if they try to autofill from adjacent cells
   */
  const handleCellSelected = (args: CellSelectEventArgs) => {
    if ('selectedRowCellIndex' in args) {
      const selectedRowCellIndex = args.selectedRowCellIndex as { rowIndex: number; cellIndexes: number[] }[]
      if (selectedRowCellIndex) {
        let errorLogged = false
        for (const rowCellIndex of selectedRowCellIndex) {
          if (rowCellIndex.cellIndexes.length > 1) {
            errorLogged = true
          }
        }
        if (errorLogged) {
          logger.warn('selectedRowCellIndex', selectedRowCellIndex)
          logger.warn('grid', grid)
          DialogUtility.alert({
            title: 'Whoops!',
            width: '250px',
            content: 'Autofilling from adjacent cells is not supported.',
            position: { X: 'center', Y: 'center' },
            closeOnEscape: true,
            isModal: true,
            close: () => {
              grid?.editModule.batchCancel()
            },
          })
        }
      }
    }
  }

  /*
   * Custom cell for the unit column. This works, but the column template seems
   * more appropriate (if it worked).
   */
  // const handleQueryCellInfo = (args: QueryCellInfoEventArgs) => {
  //   if (args.column?.field !== 'csu_report_unit') return
  //   const unit = getValue('csu_report_unit', args.data)
  //   // if (unit.match(/^\d{4}-\d{4} .*$/)) return
  //   const unitTitle = getValue('csu_report_unit_title', args.data)
  //   logger.warn('handleQueryCellInfo', unit, unitTitle)
  //   if (args.cell && unit && unitTitle) {
  //     args.cell.innerHTML = `${unit} ${unitTitle}`
  //   }
  // }

  /*
   * BROKEN: Custom view template for the unit column. This doesn't work. The template
   * only renders after a page reload.
   */
  // const unitTemplate = (args: { column?: Column; csu_report_unit?: string; csu_report_unit_title?: string }): JSX.Element | undefined => {
  //   let value = ''
  //   if (args.column && args.csu_report_unit && args.csu_report_unit_title) {
  //     // value = `${args.csu_report_unit} ${args.csu_report_unit_title}`
  //     value = `${args.csu_report_unit}`
  //   }
  //   return <div>{value}</div>
  // }

  let codeTitleObj: DropDownList
  let codeTitleEl: HTMLElement

  const codeTitleParams: IEditCell = {
    create: () => {
      codeTitleEl = document.createElement('input')
      return codeTitleEl
    },
    read: () => {
      return codeTitleObj.value
    },
    write: ({ row, rowData, column }: { row: Element | EventTarget; rowData: { [key: string]: string | number }; column: Column }) => {
      const fiscalYearEl: HTMLElement = document.getElementById('fiscalYear') as HTMLElement
      const fiscalYear = getValue('value', fiscalYearEl).split('-')[0] // expected getValue('value') to return YYYY, but it doesn't
      const csuNameEl: HTMLElement = document.getElementById('csuNames') as HTMLElement
      const csuName = getValue('value', csuNameEl)
      const rowIndex = grid?.getRowInfo(row).rowIndex as number
      codeTitleObj = new DropDownList({
        allowFiltering: true,
        dataSource: data,
        fields: { text: 'text', value: 'value' },
        filterType: 'Contains',
        placeholder: 'Select a unit code',
        popupHeight: '400px',
        popupWidth: '400px',
        // query: new Query('app_csu_alignment.csu_alignment_unitcode_title').select(['value', 'text']),
        query: new Query('app_csu_alignment.csu_alignment_unitcode_title').select(['value', 'text']).where(new Predicate('fiscal_year', 'equal', fiscalYear).and('csu_name', 'equal', csuName)),
        showClearButton: false,
        sortOrder: 'Ascending',
        value: rowData[column.field], // value,
        change: function ({ value }: ChangeEventArgs) {
          if (typeof value !== 'string') return
          const [codePart, ...titleParts] = value.split(' ')
          const titlePart = titleParts.join(' ')
          grid?.updateCell(rowIndex, 'csu_report_unit', codePart)
          grid?.updateCell(rowIndex, 'csu_report_unit_title', titlePart)
        },
      })
      codeTitleObj.appendTo(codeTitleEl)
    },
    destroy: () => {
      codeTitleObj.destroy()
    },
  }

  // let unitCodeObj: DropDownList
  // let unitCodeEl: HTMLElement

  // const unitCodeParams: IEditCell = {
  //   create: () => {
  //     unitCodeEl = document.createElement('input')
  //     return unitCodeEl
  //   },
  //   read: () => {
  //     return unitCodeObj.value // ? unitCodeObj.value : ''
  //   },
  //   write: (args: { column: Column; rowData: { [key: string]: string }; element: HTMLElement }) => {
  //     //const el = args.element as HTMLElement
  //     // const value = args.rowData[args.column.field] ? args.rowData[args.column.field] : ''
  //     unitCodeObj = new DropDownList({
  //       // fields: {
  //       //   dataSource: data, // unitData,
  //       //   query: new Query('app_csu_alignment.csu_alignment_unitcode_title').select(['value', 'text', 'title']),
  //       // },
  //       allowFiltering: true,
  //       dataSource: data, // unitData,
  //       fields: { text: 'text', value: 'value' },
  //       filterType: 'Contains',
  //       itemTemplate: '<span><span>${text}</span><span style="margin-left: 60px;">${title}</span></span>',
  //       placeholder: 'Select a unit code',
  //       popupHeight: '400px',
  //       popupWidth: '400px',
  //       query: new Query('app_csu_alignment.csu_alignment_unitcode_title').select(['value', 'text', 'title']),
  //       showClearButton: false,
  //       sortOrder: 'Ascending',
  //       value: args.rowData[args.column.field], // value,
  //       change: function () {
  //         if (grid) {
  //           const formEl = grid.element.querySelector('form')?.ej2_instances[0] as FormValidator // FormValidator extends Base<HTMLFormElement> implements INotifyPropertyChanged
  //           if (!formEl) return
  //           console.warn('formEl', formEl) // acutally a FormValidator
  //           const unitTitleFieldEl = formEl.getInputElement('csu_report_unit_title') as HTMLInputElement
  //           console.warn('formEl unitTitleFieldEl', unitTitleFieldEl) // acutally a FormValidator
  //           if (unitTitleEl) {
  //             console.warn('unitTitleEl value', formEl) // acutally a FormValidator
  //             unitTitleFieldEl.value = 'FOO' // priceObj.value * stockObj.value
  //           }
  //         }
  //       },
  //     })
  //     unitCodeObj.appendTo(unitCodeEl)
  //   },
  //   destroy: () => {
  //     unitCodeObj.destroy()
  //   },
  //   // params: {
  //   //   allowFiltering: true,
  //   //   dataSource: data, // unitData,
  //   //   placeholder: 'Select a unit code',
  //   //   popupHeight: '400px',
  //   //   popupWidth: '400px',
  //   //   showClearButton: false,
  //   //   query: new Query().from('app_csu_alignment.csu_alignment_unitcode_title').select(['value', 'text', 'title']),
  //   //   fields: { text: 'text', value: 'value' },
  //   //   filterType: 'Contains',
  //   //   filtering: (e: FilteringEventArgs) => {
  //   //     let query = new Query().from('app_csu_alignment.csu_alignment_unitcode_title').select(['value', 'text', 'title'])
  //   //     const predicate: Predicate = new Predicate('title', 'contains', e.text, true).or('text', 'contains', e.text, true)
  //   //     query = e.text != '' ? query.where(predicate) : query
  //   //     e.updateData(data, query)
  //   //   },
  //   //   itemTemplate: '<span><span>${text}</span><span style="margin-left: 60px;">${title}</span></span>',
  //   // },
  // }

  // let unitTitleObj: DropDownList
  // let unitTitleEl: HTMLElement

  // const unitTitleParams: IEditCell = {
  //   create: () => {
  //     unitTitleEl = document.createElement('input')
  //     return unitTitleEl
  //   },
  //   read: () => {
  //     return unitTitleObj.value // ? unitCodeObj.value : ''
  //   },
  //   write: (args: { column: Column; rowData: { [key: string]: string }; element: HTMLElement }) => {
  //     //const el = args.element as HTMLElement
  //     // const value = args.rowData[args.column.field] ? args.rowData[args.column.field] : ''
  //     unitTitleObj = new DropDownList({
  //       // fields: {
  //       //   dataSource: data, // unitData,
  //       //   query: new Query('app_csu_alignment.csu_alignment_unitcode_title').select(['value', 'text', 'title']),
  //       // },
  //       allowFiltering: true,
  //       dataSource: data, // unitData,
  //       fields: { text: 'text', value: 'value' },
  //       filterType: 'Contains',
  //       itemTemplate: '<span><span>${text}</span><span style="margin-left: 60px;">${title}</span></span>',
  //       placeholder: 'Select a unit code',
  //       popupHeight: '400px',
  //       popupWidth: '400px',
  //       query: new Query('app_csu_alignment.csu_alignment_unitcode_title').select(['value', 'text', 'title']),
  //       showClearButton: false,
  //       sortOrder: 'Ascending',
  //       value: args.rowData[args.column.field], // value,
  //       // change: function (args) {
  //       //   let formEle = grid.element.querySelector('form').ej2_instances[0]
  //       //   let totalCostFieldEle = formEle.getInputElement('TotalCost')
  //       //   totalCostFieldEle.value = priceObj.value * stockObj.value
  //       // },
  //     })
  //     unitTitleObj.appendTo(unitTitleEl)
  //   },
  //   destroy: () => {
  //     unitTitleObj.destroy()
  //   },
  //   // params: {
  //   //   allowFiltering: true,
  //   //   dataSource: data, // unitData,
  //   //   placeholder: 'Select a unit code',
  //   //   popupHeight: '400px',
  //   //   popupWidth: '400px',
  //   //   showClearButton: false,
  //   //   query: new Query().from('app_csu_alignment.csu_alignment_unitcode_title').select(['value', 'text', 'title']),
  //   //   fields: { text: 'text', value: 'value' },
  //   //   filterType: 'Contains',
  //   //   filtering: (e: FilteringEventArgs) => {
  //   //     let query = new Query().from('app_csu_alignment.csu_alignment_unitcode_title').select(['value', 'text', 'title'])
  //   //     const predicate: Predicate = new Predicate('title', 'contains', e.text, true).or('text', 'contains', e.text, true)
  //   //     query = e.text != '' ? query.where(predicate) : query
  //   //     e.updateData(data, query)
  //   //   },
  //   //   itemTemplate: '<span><span>${text}</span><span style="margin-left: 60px;">${title}</span></span>',
  //   // },
  // }

  let programObj: DropDownTree

  const programParams: IEditCell = {
    read: () => {
      return programObj.value ? programObj.value.join(',') : ''
    },
    write: (args: { column: Column; rowData: { [key: string]: string }; element: HTMLElement }) => {
      const el = args.element as HTMLElement
      const value = args.rowData[args.column.field] ? args.rowData[args.column.field].split(',') : []
      programObj = new DropDownTree({
        fields: {
          dataSource: data, // programData,
          query: new Query('app_csu_alignment.csu_alignment_program').select(['value', 'text', 'parentValue', 'hasChildren', 'selectable', 'expanded']),
        },
        allowFiltering: false,
        filterType: 'Contains',
        placeholder: 'Select a program',
        popupHeight: '400px',
        popupWidth: '400px',
        showClearButton: false,
        showDropDownIcon: true,
        sortOrder: 'Ascending',
        treeSettings: { loadOnDemand: true },
        value: value,
      })
      programObj.appendTo(el)
    },
    destroy: () => {
      programObj.destroy()
    },
  }

  let projectObj: DropDownTree

  const projectParams: IEditCell = {
    read: () => {
      return projectObj.value.join(',') ? projectObj.value.join(',') : ''
    },
    write: (args: { column: Column; rowData: { [key: string]: string }; element: HTMLElement }) => {
      const el = args.element as HTMLElement
      const value = args.rowData[args.column.field] ? args.rowData[args.column.field].split(',') : []
      projectObj = new DropDownTree({
        fields: {
          dataSource: data, // projectData,
          query: new Query('app_csu_alignment.csu_alignment_project').select(['value', 'text', 'parentValue', 'hasChildren', 'selectable', 'expanded']),
        },
        allowFiltering: false,
        filterType: 'Contains',
        placeholder: 'Select a project',
        popupHeight: '400px',
        popupWidth: '400px',
        showClearButton: false,
        showDropDownIcon: true,
        sortOrder: 'Ascending',
        value: value,
      })
      projectObj.appendTo(el)
    },
    destroy: () => {
      projectObj.destroy()
    },
  }

  const dateParams: IEditCell = {
    params: {
      allowFiltering: false,
      format: 'yyyy-MM-dd',
      placeholder: 'Select date',
      showClearButton: true,
      strictMode: true,
    },
  }

  const dateFormat = { type: 'date', skeleton: 'short' }
  const datetimeFormat = { type: 'dateTime', skeleton: 'short' }

  const dateValidationRules = { date: true }

  const changeCsuName = (args: ChangeEventArgs) => {
    logger.info('changeCsuName', args)
    if (grid && args.itemData.value) {
      const fiscalYearEl: HTMLElement = document.getElementById('fiscalYear') as HTMLElement
      const fiscalYear = getValue('value', fiscalYearEl).split('-')[0] // expected getValue('value') to return YYYY, but it doesn't
      grid.query = new Query().select(allColumns).where(new Predicate('aq_fy', 'equal', fiscalYear).and('aq_vp_dean_short', 'equal', args.itemData.value))
    }
  }

  const changeFiscalYear = (args: ChangeEventArgs) => {
    logger.info('changeFiscalYear', args)
    if (grid && args.itemData.value) {
      const csuNameEl: HTMLElement = document.getElementById('csuNames') as HTMLElement
      const csuName = getValue('value', csuNameEl)
      grid.query = new Query().select(allColumns).where(new Predicate('aq_fy', 'equal', args.itemData.value).and('aq_vp_dean_short', 'equal', csuName))
    }
  }

  if (!isLoggedIn) {
    return <div></div>
  }

  // prettier-ignore
  return (
    <div className="alignment-table-container">
      <div className="alignment-table-header">
        <CsuNamePicker
          csuNames={csuNames}
          changeCsuName={changeCsuName}
        />
        <FiscalYearPicker
          fiscalYears={fiscalYears}
          changeFiscalYear={changeFiscalYear}
        />
      </div>
      <GridComponent
        allowFiltering={true} // default: false
        allowGrouping={false} // default: false
        allowMultiSorting={true} // default: false
        allowPaging={true} // default: false
        allowReordering={false} // default: false
        allowResizing={false} // default: false
        allowSelection={true} // default: true
        allowSorting={true} // default: false
        autoFit={true} // default: false
        columnChooserSettings={{
          operator: 'contains', // default: 'startsWith'
          ignoreAccent: true, // default: false
        }}
        dataSource={data}
        editSettings={{
          allowAdding: false, // default: false
          allowEditing: true, // default: false
          allowDeleting: false, // default: false
          mode: 'Batch', // Normal (default) | Dialog | Batch
          allowEditOnDblClick: true, // default: true
          showConfirmDialog: false, // default: true
          showDeleteConfirmDialog: false, // default: false
        }}
        enableAutoFill={true} // default: false
        enableHeaderFocus={false} // default: false, not sure what this does
        enableVirtualization={false} // default: false
        enableImmutableMode={false} // DON'T ENABLE, BREAKS COLUMN CHOOSER: default: false, requires primary key
        filterSettings={{
          columns: [], // default: []
          type: 'FilterBar', // Menu | CheckBox | FilterBar (default) | Excel
          mode: 'Immediate', // OnEnter | Immediate (default)
          showFilterBarStatus: false, // default: true
          immediateModeDelay: 1500, // default: 1500
          enableCaseSensitivity: false,
          ignoreAccent: true,
          showFilterBarOperator: false,
          operators: {
            stringOperator: [
              { text: 'Starts With', value: 'startswith' },
              { text: 'Contains', value: 'contains' },
            ],
            numberOperator: [{ text: 'Equal', value: 'equal' }],
            dateOperator: [{ text: 'Equal', value: 'equal' }],
          },
        }}
        frozenColumns={7} // defaut: 0, frozenColumns is not compatible with freeze or isFrozen
        // frozenRows={4}
        gridLines="Both" // default: 'Default'
        pageSettings={{
          currentPage: 1, // default: 1
          pageSize: 100, // default: 12
          pageCount: 8, // default: 8
          enableQueryString: false, // default: false
          pageSizes: [25, 50, 100], // default: false
        }}
        query={query}
        ref={(g) => {
          logger.info('ref', g)
          if (g) {
            grid = g
          }
        }}
        selectionSettings={{
          mode: 'Cell', // Cell | Row (default) | Both
          cellSelectionMode: 'Box', // Flow (default) | Box | BoxWithBorder
          type: 'Multiple', // Single (default) | Multiple
        }}
        showColumnChooser={true} // default: false
        showColumnMenu={false} // default: false
        sortSettings={{
          columns: [
            { field: 'aq_unit', direction: 'Ascending' },
            { field: 'aq_account_nbr', direction: 'Ascending' },
            // { field: 'csu_alignment_md5_key', direction: 'Ascending' },
          ],
        }}
        toolbar={[
          'Update',
          'Cancel',
          // { type: 'Separator' },
          // BROKEN { id: 'csuName', text: 'CSU', align: 'Center' type='Input', template: csuNameTemplate },
          // BROKEN { id: 'fiscalYear', text: 'FY', align: 'Center' type='Input', template: fiscalYearTemplate },
          'ColumnChooser',
        ]}
        width={'100%'} // default: 'auto'
        /**
         * -----------------------------------------------------------------------
         * Triggers
         * -----------------------------------------------------------------------
         */
        // created?: EmitType<Object>;
        created={() => {
          logger.info('created')
        }}
        // destroyed?: EmitType<Object>;
        destroyed={(args) => {
          logger.info('destroyed', args)
        }}
        // load?: EmitType<Object>;
        load={(args: LoadEventArgs) => {
          logger.info('load', args)
          // args.requireTemplateRef = false // does this really improve performance?
        }}
        // rowDataBound?: EmitType<RowDataBoundEventArgs>;
        // queryCellInfo?: EmitType<QueryCellInfoEventArgs>;
        // queryCellInfo={(args: QueryCellInfoEventArgs) => {
        //   // logger.info('queryCellInfo', args) // this is called a lot
        //   handleQueryCellInfo(args)
        // }}
        // headerCellInfo?: EmitType<HeaderCellInfoEventArgs>;
        // actionBegin?: EmitType<PageEventArgs | GroupEventArgs | FilterEventArgs | SearchEventArgs | SortEventArgs | AddEventArgs | SaveEventArgs | EditEventArgs | DeleteEventArgs | ActionEventArgs>;
        actionBegin={(args) => {
          logger.info(args.name, args)
        }}
        // actionComplete?: EmitType<PageEventArgs | GroupEventArgs | FilterEventArgs | SearchEventArgs | SortEventArgs | AddEventArgs | SaveEventArgs | EditEventArgs | DeleteEventArgs | ActionEventArgs>;
        actionComplete={(args) => {
          logger.info('actionComplete', args)
          handleActionComplete(args)
        }}
        // actionFailure?: EmitType<FailureEventArgs>;
        actionFailure={(args: FailureEventArgs) => {
          logger.warn('actionFailure', args)
        }}
        // dataBound?: EmitType<Object>;
        dataBound={(args) => {
          logger.info(args.name)
        }}
        // recordDoubleClick?: EmitType<RecordDoubleClickEventArgs>;
        recordDoubleClick={(args: RecordDoubleClickEventArgs) => {
          logger.info(args.name, args)
          handleDoubleClick(args)
        }}
        // recordClick?: EmitType<RecordClickEventArgs>;
        // rowSelecting?: EmitType<RowSelectingEventArgs>;
        // rowSelected?: EmitType<RowSelectEventArgs>;
        // rowDeselecting?: EmitType<RowDeselectingEventArgs>;
        // rowDeselected?: EmitType<RowDeselectEventArgs>;
        // cellSelecting?: EmitType<CellSelectingEventArgs>;
        // cellSelected?: EmitType<CellSelectEventArgs>;
        cellSelected={(args: CellSelectEventArgs) => {
          logger.info('cellSelected', args)
          handleCellSelected(args)
        }}
        // cellDeselecting?: EmitType<CellDeselectEventArgs>;
        // cellDeselected?: EmitType<CellDeselectEventArgs>;
        // columnSelecting?: EmitType<ColumnSelectingEventArgs>;
        // columnSelected?: EmitType<ColumnSelectEventArgs>;
        // columnDeselecting?: EmitType<ColumnDeselectEventArgs>;
        // columnDeselected?: EmitType<ColumnDeselectEventArgs>;
        // columnDragStart?: EmitType<ColumnDragEventArgs>;
        // columnDrag?: EmitType<ColumnDragEventArgs>;
        // columnDrop?: EmitType<ColumnDragEventArgs>;
        // printComplete?: EmitType<PrintEventArgs>;
        // beforePrint?: EmitType<PrintEventArgs>;
        // pdfQueryCellInfo?: EmitType<PdfQueryCellInfoEventArgs>;
        // pdfHeaderQueryCellInfo?: EmitType<PdfHeaderQueryCellInfoEventArgs>;
        // pdfAggregateQueryCellInfo?: EmitType<AggregateQueryCellInfoEventArgs>;
        // excelAggregateQueryCellInfo?: EmitType<AggregateQueryCellInfoEventArgs>;
        // exportDetailDataBound?: EmitType<ExportDetailDataBoundEventArgs>;
        // excelQueryCellInfo?: EmitType<ExcelQueryCellInfoEventArgs>;
        // excelHeaderQueryCellInfo?: EmitType<ExcelHeaderQueryCellInfoEventArgs>;
        // beforeExcelExport?: EmitType<Object>;
        // excelExportComplete?: EmitType<ExcelExportCompleteArgs>;
        // beforePdfExport?: EmitType<Object>;
        // pdfExportComplete?: EmitType<PdfExportCompleteArgs>;
        // rowDragStartHelper?: EmitType<RowDragEventArgs>;
        // detailDataBound?: EmitType<DetailDataBoundEventArgs>;
        // rowDragStart?: EmitType<RowDragEventArgs>;
        // rowDrag?: EmitType<RowDragEventArgs>;
        // rowDrop?: EmitType<RowDragEventArgs>;
        // toolbarClick?: EmitType<ClickEventArgs>;
        // beforeOpenColumnChooser?: EmitType<ColumnChooserEventArgs>;
        // beforeOpenAdaptiveDialog?: EmitType<AdaptiveDialogEventArgs>;
        // batchAdd?: EmitType<BatchAddArgs>;
        // batchDelete?: EmitType<BatchDeleteArgs>;
        // batchCancel?: EmitType<BatchCancelArgs>;
        // beforeBatchAdd?: EmitType<BeforeBatchAddArgs>;
        // beforeBatchDelete?: EmitType<BeforeBatchDeleteArgs>;
        // beforeBatchSave?: EmitType<BeforeBatchSaveArgs>;
        beforeBatchSave={(args: BeforeBatchSaveArgs) => {
          logger.info('beforeBatchSave', args)
          handleBeforeBatchSave(args)
        }}
        // beginEdit?: EmitType<BeginEditArgs>;
        // commandClick?: EmitType<CommandClickEventArgs>;
        // cellEdit?: EmitType<CellEditArgs>;
        // cellSave?: EmitType<CellSaveArgs>;
        // cellSaved?: EmitType<CellSaveArgs>;
        // resizeStart?: EmitType<ResizeArgs>;
        // resizing?: EmitType<ResizeArgs>;
        // resizeStop?: EmitType<ResizeArgs>;
        // keyPressed?: EmitType<KeyboardEventArgs>;
        // beforeDataBound?: EmitType<BeforeDataBoundArgs>;
        beforeDataBound={(args: BeforeDataBoundArgs) => {
          logger.info('beforeDataBound', args)
        }}
        // contextMenuOpen?: EmitType<BeforeOpenCloseMenuEventArgs>;
        // contextMenuClick?: EmitType<MenuEventArgs>;
        // columnMenuOpen?: EmitType<ColumnMenuOpenEventArgs>;
        // columnMenuClick?: EmitType<MenuEventArgs>;
        // checkBoxChange?: EmitType<CheckBoxChangeEventArgs>;
        // beforeCopy?: EmitType<BeforeCopyEventArgs>;
        // beforePaste?: EmitType<BeforePasteEventArgs>;
        // beforeAutoFill?: EmitType<BeforeAutoFillEventArgs>;
        // columnDataStateChange?: EmitType<ColumnDataStateChangeEventArgs>;
        // dataStateChange?: EmitType<DataStateChangeEventArgs>;
        // dataSourceChanged?: EmitType<DataSourceChangedEventArgs>;
        // exportGroupCaption?: EmitType<ExportGroupCaptionEventArgs>;
        // lazyLoadGroupExpand?: EmitType<LazyLoadArgs>;
        // lazyLoadGroupCollapse?: EmitType<LazyLoadArgs>;
      >
        <ColumnsDirective>
          {/* Left columns */}
          <ColumnDirective field='aq_account_nbr'              headerText='Acct Number'             type='string'   visible={true}  allowEditing={false} showInColumnChooser={false} autoFit={true} minWidth={40}></ColumnDirective>
          <ColumnDirective field='aq_short_title'              headerText='Acct Title'              type='string'   visible={true}  allowEditing={false} showInColumnChooser={false} autoFit={true} minWidth={40}></ColumnDirective>
          <ColumnDirective field='aq_account_status'           headerText='Acct Status'             type='string'   visible={true}  allowEditing={false} showInColumnChooser={false} autoFit={true} minWidth={40}></ColumnDirective>
          <ColumnDirective field='csu_report_code_title'       headerText='CSU Reporting UC'        type='string'   visible={true}  allowEditing={true}  showInColumnChooser={false} autoFit={true} minWidth={100} editType='dropdownedit'   edit={codeTitleParams}></ColumnDirective>
          <ColumnDirective field='csu_program'                 headerText='CSU Program'             type='string'   visible={true}  allowEditing={true}  showInColumnChooser={false} autoFit={true} minWidth={280} editType='stringedit'     edit={programParams}></ColumnDirective>
          <ColumnDirective field='csu_project'                 headerText='CSU Project'             type='string'   visible={true}  allowEditing={true}  showInColumnChooser={false} autoFit={true} minWidth={280} editType='stringedit'     edit={projectParams}></ColumnDirective>
          <ColumnDirective field='csu_project_exp_date'        headerText='CSU Project Exp'         type='date'     visible={true}  allowEditing={true}  showInColumnChooser={false} autoFit={true} minWidth={160} editType='datepickeredit' edit={dateParams} validationRules={dateValidationRules} format={dateFormat}></ColumnDirective>
          {/* Right columns */}
          <ColumnDirective field='aq_unit'                     headerText='Unit Code'               type='string'   visible={true}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='bg_short_title'              headerText='Budget Group Title'      type='string'   visible={true}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='bg_group_status'             headerText='Budget Group Status'     type='string'   visible={true}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='bg_account_nbr'              headerText='Budget Group'            type='string'   visible={true}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='aq_unit_subcode'             headerText='Unit Subcode'            type='string'   visible={true}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='aq_short'                    headerText='Unit Title'              type='string'   visible={true}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='aq_dept_unit'                headerText='Department Unit'         type='string'   visible={true}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='aq_dept_short'               headerText='Department Title'        type='string'   visible={true}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          {/* TODO: Not displayed by default */}
          <ColumnDirective field='aq_vp_dean_unit'             headerText='VP/Dean Unit Code'       type='string'   visible={false}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='aq_vp_dean_short'            headerText='VP/Dean Title'           type='string'   visible={false}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='aq_comp'                     headerText='Unit Component'          type='string'   visible={false}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='aq_unit_head_name'           headerText='Unit Head'               type='string'   visible={false}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='aq_unit_head_eid'            headerText='Unit Head EID'           type='string'   visible={false}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='aq_137_account_aq_comp'      headerText='Account Component'       type='string'   visible={false}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='aq_138_budget_group_aq_comp' headerText='Budget Group Component'  type='string'   visible={false}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          <ColumnDirective field='csu_updated_ts'              headerText='Last Updated'            type='datetime' visible={false}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80} format={datetimeFormat}></ColumnDirective>
          <ColumnDirective field='csu_updated_by'              headerText='Updater EID'             type='string'   visible={false}  allowEditing={false} showInColumnChooser={true}  autoFit={true} minWidth={80}></ColumnDirective>
          {/* Hidden columns */}
          <ColumnDirective field='csu_alignment_md5_key'       headerText='Primary Key'             type='string'   visible={false} allowEditing={false} showInColumnChooser={false} autoFit={false} minWidth={200} isPrimaryKey={true}></ColumnDirective>
          <ColumnDirective field='aq_fy'                       headerText='Fiscal Year'             type='number'   visible={false} allowEditing={false} showInColumnChooser={false} autoFit={false} minWidth={80}></ColumnDirective>
          <ColumnDirective field='csu_report_unit'             headerText='CSU Reporting UC'        type='string'   visible={false} allowEditing={false} showInColumnChooser={false} autoFit={false} minWidth={80}></ColumnDirective>
          <ColumnDirective field='csu_report_unit_title'       headerText='Reporting UC Title'      type='string'   visible={false} allowEditing={false} showInColumnChooser={false} autoFit={false} minWidth={80}></ColumnDirective>
        </ColumnsDirective>
        <Inject
          services={[
            ColumnChooser,
            Edit,
            Filter,
            Freeze,
            Group,
            Page,
            Resize,
            Selection,
            Sort,
            Toolbar,
          ]}
        />
      </GridComponent>
    </div>
  )
}
