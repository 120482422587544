import React from 'react'
import { AppBarComponent } from '@syncfusion/ej2-react-navigations'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons'
import { MenuEventArgs } from '@syncfusion/ej2-react-navigations'
import { useState } from 'react'

interface AppBarProps {
  isLoggedIn?: boolean
  username?: string
  onLogin: () => void
  onLogout: () => void
  resetPersistedState: () => void
  setError: (message: string) => void
  isLoggingIn: boolean
}

export const AppBar: React.FC<AppBarProps> = ({ isLoggedIn = false, username = '', onLogin, onLogout, resetPersistedState, setError, isLoggingIn }) => {
  const [isSettingsVisible, setIsSettingsVisible] = useState<boolean>(false)
  const parsedUsername = username?.includes('\\') ? username?.substring(username?.lastIndexOf('\\') + 1) : username

  //let settings: DialogComponent

  const hideSettingsDialog = () => {
    setIsSettingsVisible(false) // Hide the settings dialog
  }

  const handleSelect = (args: MenuEventArgs) => {
    switch (args.item.id) {
      case 'settings':
        setError('error')
        // settings.show();
        break
      case 'logout':
        onLogout()
        break
      // You can add more cases if needed
      default:
        break
    }
  }

  const items = [
    {
      text: 'Documentation',
      url: 'https://utexas.edu',
      disabled: true,
      id: 'documentation',
    },
    {
      text: 'Settings',
      disabled: true,
      id: 'settings',
    },
    {
      separator: true,
    },
    {
      text: 'Logout',
      id: 'logout',
    },
  ]

  const content = (): JSX.Element => {
    return (
      <div className="dialogContent">
        <label className="dialogLabel">Saved preferences</label>
        <ButtonComponent
          cssClass="e-outline"
          onClick={resetPersistedState}
        >
          Reset
        </ButtonComponent>
      </div>
    )
  }

  return (
    <div id="dialog-target">
      <AppBarComponent>
        <span>Alignment Table Management</span>
        <div className="e-appbar-spacer"></div>
        {isLoggedIn ? (
          <DropDownButtonComponent
            content={parsedUsername}
            cssClass="e-inherit"
            items={items}
            select={handleSelect}
          />
        ) : (
          <ButtonComponent
            cssClass="e-outline"
            onClick={onLogin}
            aria-label="Sign in"
            disabled={isLoggingIn}
          >
            {isLoggingIn ? 'Signing in...' : 'Sign In'}
          </ButtonComponent>
        )}
      </AppBarComponent>
      <DialogComponent
        header={'Settings'}
        content={content}
        buttons={[{ click: handleSelect, buttonModel: { content: 'Close', isPrimary: true } }]}
        closeOnEscape={false}
        isModal={true}
        overlayClick={hideSettingsDialog}
        //ref={(dialog) => (settings = dialog!)}
        showCloseIcon={true}
        visible={isSettingsVisible}
        width="400px"
      ></DialogComponent>
    </div>
  )
}
