import { ChangeEventArgs } from '@syncfusion/ej2-react-dropdowns'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'

interface FiscalYearPickerProps {
  fiscalYears: string[]
  changeFiscalYear: (event: ChangeEventArgs) => void // Adjust the event type if you have a more specific type.
}

export const FiscalYearPicker: React.FC<FiscalYearPickerProps> = ({ fiscalYears, changeFiscalYear }) => {
  const data = fiscalYears.map((year) => {
    return {
      value: year,
      text: `${year}-${(+year + 1).toString().slice(-2)}`,
    }
  })

  return (
    <DropDownListComponent
      id="fiscalYear"
      change={changeFiscalYear}
      dataSource={data}
      placeholder="Select a fiscal year"
      value={data[0].value} // value={fiscalYears[0] || ''}
      text={data[0].text}
      width={200}
    />
  )
}
