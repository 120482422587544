import { ChangeEventArgs } from '@syncfusion/ej2-react-dropdowns'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'

interface CsuNamePickerProps {
  csuNames: string[]
  changeCsuName: (event: ChangeEventArgs) => void // Adjust the event type if you have a more specific type.
}

export const CsuNamePicker: React.FC<CsuNamePickerProps> = ({ csuNames, changeCsuName }) => {
  return (
    <DropDownListComponent
      id="csuNames"
      change={changeCsuName}
      dataSource={csuNames}
      placeholder="Select a CSU name"
      value={csuNames[0] ? csuNames[0] : ''}
      width={200}
    />
  )
}
